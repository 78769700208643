<template>
  <CCard id="printArea">
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <List ref="list" 
        :fields=fields
        :items.sync=adminInfoList
        :loading.sync=loading
        showSearch
        :comboboxData="comboboxData"
        sorter      
        :defaultSort=defaultSort
        showRegBtn
        itemsPerPageSelect
        :itemsPerPage=itemPerPage
        menuKey=303
      >      
        <template #no="{index}">
            <td>
              {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
            </td>
        </template>

        <!--아이템별 버튼 재정의-->     
        <template #show_buttons="{item, index}">
          <td class="py-2" >
            <CButton
              size="sm" 
              square
              @click="detailItem(item, index)"
              v-c-tooltip="'상세보기'"
              class="btn btn-primary"
              >
              <CIcon
                :content="$options.icons.cilMagnifyingGlass"
              />
            </CButton>
            <!-- <CButton
              square
              size="sm"
              @click="modifyItem(item, index)"
              v-c-tooltip="'수정'"
              class="btn btn-primary btn-lft-margin"
            >
              <CIcon
                :content="$options.icons.cilPencil"
              />
            </CButton> -->
            <CButton
              v-if="item.adminType=='1' && $hasWritePermission('303')"
              square
              size="sm"
              @click="deleteItem(item, index)"
              v-c-tooltip="'삭제'"
              class="btn btn-primary btn-lft-margin"
            >
              <CIcon
                :content="$options.icons.cilTrash"
              />
            </CButton>
          </td>
        </template>         
      </List>
      <page-link ref="page"
        :pageLinkCount=pagePerLink
        :listRowCount=itemPerPage
      />
    </CCardBody>
  </CCard>
</template>

<script>
import List from "@/views/common/List.vue";
import PageLink from '@/views/common/PageLink.vue';
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
//import ModifyAppModal from './ModifyAppModal'
import { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory, cilDataTransferDown} from '@coreui/icons'
export default {
  name: 'accountList',
  icons: { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory, cilDataTransferDown},
  components: {
    List,
    PageLink,
    CIcon,
    
  },
  computed: {
      // 목록에 들어갈 데이터
      adminInfoList(){ 
        return this.$store.state.adminInfo.adminInfoList || []
      },
      adminNmStr () {
        let adminNmStr = ""
        let checkedList = this.$refs.list.getCheckedList()
        for(var i in checkedList){
          for(var j in this.adminInfoList){
            if(this.adminInfoList[j].adminKey == checkedList[i]){
              adminNmStr+=(adminNmStr==""?"":",")+this.adminInfoList[j].adminNm
              break;
            }
          }
        }  
        return adminNmStr
      },
      adminKeyStr(){
        let adminKeyStr = ""
        let checkedList = this.$refs.list.getCheckedList()
        for(var i in checkedList)  adminKeyStr+= (adminKeyStr==""?"":",")+checkedList[i]
        return adminKeyStr
      },      
      totalCnt(){
        return this.$store.state.adminInfo.adminInfoListTotalCnt
      }

  },
  data() {
    return {
      searchType:"adminId", // 콤보박스 디폴트 값(하기 comboboxData의 value로 설정)
      // 검색조건 설정
      comboboxData:[{label: '아이디', value: 'adminId'},{label: '이름', value:'adminNm'},{label: '연락처', value:'phoneNumber'}],
      // 그리드 컬럼 설정
      fields:[
          { key: 'no', label:'No', _style: { width: '2%'} },
          { key: 'adminId', label:'아이디', _style: { width: '10%'} },
          { key: 'adminType', label:'관리자구분', _style: { width: '10%'}, codeYn:true },
          { key: 'adminNm', label:'이름', _style: { width: '5%'} },
          { key: 'email', label:'이메일', _style: { width: '20%'}},
          { key: 'phoneNumber', label:'연락처', _style: { width: '10%'} },
          { key: 'ipYn', label:'IP할당', _style: { width: '2%'}, badgeYn:true, codeYn:true },
          { key: 'groupNm', label:'권한명', sorter: false},
          { key: 'regDate', label:'등록일', _style: { width: '10%'} },
          { 
            key: 'show_buttons', 
            label: '', 
            _style: { width: '13%'}, 
            sorter: false,
          },
      ],
      pagePerLink: 5, // 하단에 보여줄 페이지번호 개수
      defaultSort: "-regDate", // 기본 정렬 설정. 내림차순은 -memberName
      pageNum: 1, // 페이지번호
      itemPerPage:8,  // 한페이지당 보여줄 데이터수
      loading:false,
      alert:{ 
        noItem:"선택된 아이템이 없습니다.",
      },
      searchDate: [new Date(), new Date()],
    }
  },
  props: {
    caption: {
      type: String,
      default: '계정관리'
    },
  },
  mounted(){
    this.searchList() 
  },
  methods: {
    // 리스트조회
    async searchList(){   
      let payload = this.$refs.list.getParams()
      this.loading=true 
      try {
        await this.$store.dispatch('adminInfo/getAdminInfoList', payload)
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }
    },
    // 개별 상세보기 (<List> 내에 show_buttons인 Template 정의 필요 )
    detailItem(item, index){ 
      this.$store.commit("dataStore/adminInfo_adminKey", item.adminKey);
      this.$store.commit("dataStore/adminInfo_mode", '0');
      this.$router.push({name: 'AccountInfo'})
    },
    // 개별 수정 (<List> 내에 show_buttons인 Template 정의 필요 )
    modifyItem(item, index){ 
      this.$store.commit("dataStore/adminInfo_adminKey", item.adminKey);
      this.$store.commit("dataStore/adminInfo_mode", '1');
      this.$router.push({name: 'AccountInfo'})
    },
    // 개별 삭제 (<List> 내에 show_buttons인 Template 정의 필요 )
    async deleteItem(item, index){ 
      if(item.adminType == '0'){
        alert("슈퍼관리자는 삭제할 수 없습니다.")
        return
      }
    
      if(window.confirm("삭제하시겠습니까?")) {
        this.loading=true 
        try {
            await this.$store.dispatch('adminInfo/deleteAdminInfo', item.adminKey)
            alert("삭제하였습니다.")
            this.loading=false
            this.searchList()
        } catch(error){
            this.$response(error)
            this.loading=false
        } 
      }
    },
    // 등록 (<List>에 showRegBtn 값 넘겨줘야함)
    regItems(){
      this.$router.push({name: 'RegistAccount'})
    },
    // 일괄삭제 (<List>에 showDeleteBtn 값 넘겨줘야함)
    // async deleteItems(){
    //   var checkedList = this.$refs.list.getCheckedList();
    //   if(checkedList.length == 0){    
    //     alert(this.alert.noItem)
    //   }
    //   else{        
    //     if(window.confirm(checkedList.length+"건의 계정정보를 일괄삭제하시겠습니까?")) {
    //       this.loading=true 
    //       try {
    //           await this.$store.dispatch('adminInfo/deleteAdminInfo', this.adminKeyStr)
    //           alert("삭제하였습니다.")
    //           this.loading=false
    //           this.searchList()
    //       } catch(error){
    //           this.$response(error)
    //           this.loading=false
    //       } 
    //     }
    //   }
    // },
    
  }
}
</script>